/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  categories: [],
  mainCategories: [],
  myError: null,
};

const categorSlice = createSlice({
  name: 'categor',
  initialState,
  reducers: {
    setCategor(state, action) {
      state.categories = action.payload;
    },
    setMainCategor(state, action) {
      state.mainCategories = action.payload;
    },
    deleteCategors(state, action) {
      state.categories = state.categories.filter((categor) => categor.id !== action.payload);
    },
    deleteMainCategors(state, action) {
      state.mainCategories = state.mainCategories.filter((mainCategor) => mainCategor.id !== action.payload);
    },
    updateCategors(state, action) {
      state.categories = state.categories.map((account) => (account.id === action.payload.id ? action.payload : account));
    },
    setError(state, action) {
      state.myError = action.payload;
    },
  },
});

export const {
  setCategor,
  deleteCategors,
  updateCategors,
  setMainCategor,
  deleteMainCategors,
  setError,
} = categorSlice.actions;
export default categorSlice.reducer;

// process.env.REACT_APP_BASEURL
export const allCategor = () => (dispatch) => {
  axios.get(`${process.env.REACT_APP_BASEURL}/api/addcategory`)
    .then((res) => dispatch(setCategor(res.data.modifiedUnderCategories)))
    .catch(console.log);
};

export const allMainCategor = () => (dispatch) => {
  axios.get(`${process.env.REACT_APP_BASEURL}/api/addcategory`)
    .then((res) => dispatch(setMainCategor(res.data.allLevels)))
    .catch(console.log);
};

export const deleteCategor = (id) => (dispatch) => {
  axios.delete(`${process.env.REACT_APP_BASEURL}/api/addcategory/${id}`)
    .then(() => dispatch(deleteCategors(id)))
    .catch(console.log);
};

export const deleteMainCategor = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASEURL}/api/addcategory/level/${id}`);
    dispatch(deleteMainCategors(id));
  } catch (error) {
    console.log(error); // Логируем ошибку для отладки
    dispatch(setError('Удалить можно только категории без подкатегорий')); // Устанавливаем ошибку в состояние

    // Сбрасываем ошибку через 5 секунд
    setTimeout(() => {
      dispatch(setError(null));
    }, 3000);
  }
};

export const editCategor = (id, updatedData) => (dispatch) => {
  axios.put(`${process.env.REACT_APP_BASEURL}/api/addcategory/${id}`, updatedData)
    .then((res) => dispatch(updateCategors(res.data)))
    .catch(console.log);
};
