import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import MyModal from '../MyModal/MyModal';
import { setModal } from '../../redux/Slices/ModalSlice/modalSlice';
import { allCategor, allMainCategor, deleteMainCategor } from '../../redux/Slices/CategorySlice/CategorSlice';

export default function ShablonCategory({ toggleModal, modalOpen }) {
  const dispatch = useDispatch();
  const final = useSelector((state) => state.categor.mainCategories);
  const myError = useSelector((state) => state.categor.myError);
  console.log(myError);
  const modal = useSelector((state) => state.modal);
  const [seeAlert, setSeeAlert] = useState(false);
  const [act, setAct] = useState(false);
  const [myId, setMyId] = useState(final.length > 0 ? final.map((el) => el.name)[0] : '');
  const [input, setInput] = useState({
    categor: '',
    undercat: '',
  });

  useEffect(() => {
    dispatch(allMainCategor());

    if (final.length > 0 && !act) {
      setInput((prev) => ({ ...prev, categor: final[0].name }));
    }
  }, [dispatch, act]);

  const clickHandler = () => {
    setAct(!act);
    setInput({
      categor: '',
      undercat: '',
    });
  };

  const inputHandler = (e) => {
    if (e.target.name === 'categor') {
      setMyId(e.target.value);
    }
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  console.log(myId, '***');
  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.post(`${process.env.REACT_APP_BASEURL}/api/addcategory`, input);
      if (res.data.message === 'Заполните обязательные поля!') {
        dispatch(setModal(!modal));
      } else if (res.data.message === 'ок!') {
        dispatch(allCategor());
        toggleModal(false);
        setSeeAlert(true);
        setInput({
          categor: '',
          undercat: '',
        });
        setTimeout(() => {
          setSeeAlert(false);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCategorHadler = async () => {
    if (myId) {
      console.log('deleteCategorHadler');
      await dispatch(deleteMainCategor(myId));
      dispatch(allMainCategor());
    }
  };
  return (
    <div className="mt-5">
      <Row className="justify-content-center">
        <Col lg="6">
          <Card className="border-0 shadow-lg mb-4">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Форма для создания категорий
              </CardTitle>
              <Form onSubmit={submitHandler}>
                <FormGroup>
                  <Label for="exampleSelect">Выбрать из уже созданных категорий:</Label>
                  <Input
                    disabled={act}
                    id="exampleSelect"
                    name="categor"
                    type="select"
                    value={input.categor}
                    onChange={inputHandler}
                  >
                    {final.map((category) => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">
                    Или создать новую Категорию
                    {' '}
                    <Button color="success" size="sm" onClick={clickHandler}>
                      {!act ? 'Создать' : 'Не создавать'}
                    </Button>
                  </Label>
                  <Label for="exampleDeleteHandler">
                    Удалить Категорию
                    {' '}
                    <Button
                      color="danger"
                      size="sm"
                      onClick={deleteCategorHadler}
                    >
                      Удалить
                    </Button>
                  </Label>
                  <Input
                    hidden={!act}
                    id="exampleEmail"
                    name="categor"
                    placeholder="Введите название категории..."
                    type="text"
                    value={input.categor}
                    onChange={inputHandler}
                  />
                </FormGroup>
                {myError && <Alert color="danger">{myError}</Alert>}
                <FormGroup>
                  <Label for="examplePassword">Подкатегория</Label>
                  <Input
                    id="examplePassword"
                    name="undercat"
                    placeholder="Введите подназвание категории..."
                    type="text"
                    value={input.undercat}
                    onChange={inputHandler}
                  />
                </FormGroup>
                {seeAlert && <Alert color="success">Категория/Подкатегория создана</Alert>}
                <Button color="primary" type="submit">Создать</Button>
                <MyModal />
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
